import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const ServiceContent = ({ name, title, content, fluid, link }) => {
  return (
    <article className={`${name}`}>
      <Img fluid={fluid} alt={`${title}概要`} className={`${name}__img`} />
      <div className={`${name}__wrap`}>
        <h2>{title}</h2>
        <div>
          <p>{content}</p>
          <Link to={link}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
            <span>詳細</span>
          </Link>
        </div>
      </div>
    </article>
  )
}

export default ServiceContent
