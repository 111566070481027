import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import CorpEstablish from "../components/service/corpEstablish"
import CorpOfficer from "../components/service/corpOfficer"
import BackContact from "../components/backContact"

const Corp = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      corp: file(relativePath: { eq: "newCompany.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "株式会社、合同会社の設立や、会社の役員変更の手続きについて、大阪の司法書士事務所、司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="会社設立　会社役員変更　電子定款 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.corp.childImageSharp.original.src}
        pageimgw={data.corp.childImageSharp.original.width}
        pageimgh={data.corp.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"corp"}
          fluid={data.corp.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"会社設立・会社役員変更概要"}
        />
        <section className="corp">
          <div className="corp__container">
            <h1 className="corp__title">会社設立・会社役員変更概要</h1>
            <div className="corp__service__list">
              <CorpEstablish />
              <CorpOfficer />
            </div>
          </div>
          <BackContact link={"/"} linkName={"Topページ"} />
        </section>
      </div>
    </Layout>
  )
}

export default Corp
