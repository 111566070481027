import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const CorpEstablish = () => {
  const data = useStaticQuery(graphql`
    query {
      establish: file(relativePath: { eq: "establish.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `法人として、会社を始めるには設立登記をしなければなりません。
とりあえず株式会社を設立するお客様も多いですが、株式上場を目指すわけではない、設立コストを抑えたいという方には、合同会社にするという選択肢もお考えいただければと思います。
弊所では、収入印紙不要の電子定款にも対応しておりますので、是非ご相談下さい。
  `

  return (
    <ServiceContent
      name={"corp__establish"}
      title={"会社設立"}
      content={content}
      fluid={data.establish.childImageSharp.fluid}
      link={"/establish/"}
    />
  )
}

export default CorpEstablish
