import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const CorpOfficer = () => {
  const data = useStaticQuery(graphql`
    query {
      officer: file(relativePath: { eq: "officer.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `役員の就任・任期満了・再任・辞任等は、登記事項の変更にあたりますので、その旨の登記をしなければなりません。
役員の任期は定款の記載事項ですが、任期が長期になっている場合や、補欠役員の規定の不備等により変更になっいることに気が付かないことも度々起こっております。
会社法では、会社の登記事項に変更があった日から２週間以内にその変更登記をしなければならないとされています。その違反には、１００万円以下の過料の制裁がありますので、ご注意ください。
弊所では役員変更登記はもちろん、定款チェック・見直し等のご相談にも対応しておりますので、お気軽にご相談下さい。
`

  return (
    <ServiceContent
      name={"corp__officer"}
      title={"役員変更"}
      content={content}
      fluid={data.officer.childImageSharp.fluid}
      link={"/officerchange/"}
    />
  )
}

export default CorpOfficer
